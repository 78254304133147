import React, { useState } from "react";
import style from "./style.module.css";
import {useNavigate} from 'react-router-dom'; 

const Button =(props)=>{
    const url = "https://monitoring.wonderwoman.site/gym.php";
    //const url = "https://192.168.43.136/gym/gym.php";
    //const url ="https://09ab-182-2-71-244.ngrok-free.app/gym/gym.php";
    const navigate = useNavigate();
    const[pilihanWaktu,setPilihanWaktu]=useState(null);
    //function kirim login
    const KirimLogin=()=>{
        let passLogin = localStorage.getItem("passwordLogin");
        let pilihanLogin = localStorage.getItem("pilihanLogin");
        if(pilihanLogin===null){
            props.ValKirim("Silakan pilih dahulu");
        }
        else if(pilihanLogin === "loginZumba" && passLogin === "akunZumba"
       
        ){
navigate("/zumba");
localStorage.setItem("loginSebagai","zm");
localStorage.removeItem("passwordLogin");
localStorage.removeItem("pilihanLogin");
        }
        else if(pilihanLogin === "loginGym" && passLogin === "gymAkun"
       
        ){
navigate("/gym");
localStorage.setItem("loginSebagai","y");
localStorage.removeItem("passwordLogin");
localStorage.removeItem("pilihanLogin");
        }
        else if(pilihanLogin === "loginOwner" && passLogin === "Rolant180807"
       
        ){
navigate("/owner");
localStorage.setItem("loginSebagai","0wr");
localStorage.removeItem("passwordLogin");
localStorage.removeItem("pilihanLogin");
        }
        else{
            props.ValKirim("Password Salah");
        }
    }
    
    // function kirim member gym baru
    const KirimMemberGymBaru=()=>{
        let nama = localStorage.getItem("namaMemberGym");
        let alamat = localStorage.getItem("alamatMemberGym");
        let nomor = localStorage.getItem("nomorMemberGym");
        let waktu = localStorage.getItem("waktuMemberGym");
        let status = localStorage.getItem("statusMemberGym");
        let namaAfiliator = localStorage.getItem("namaAfiliatorMemberGym");
        let nomorHpAfiliator = localStorage.getItem("nomorHpAfiliatorMemberGym");
        let rfid = localStorage.getItem("rfidVisitorGym");
     
       //
       let today = new Date();
       let year = today.getFullYear();
       let month = today.getMonth(); // Bulan dimulai dari 0
       let day = today.getDate();
       
       // Format tanggal hari ini
       let formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  
      let monthSelanjutnya;
      if(waktu === "1 Bulan"){
        monthSelanjutnya = month + 1;
      
      }else if(waktu === "3 Bulan"){
        monthSelanjutnya = month + 3;
      }
      else if(waktu === "6 Bulan"){
        monthSelanjutnya = month + 6;
      }
      else if(waktu === "12 Bulan"){
        monthSelanjutnya = month + 12;
      }
       // Menambahkan satu bulan ke bulan saat ini
   
     
       
       // Penyesuaian tahun jika bulan melampaui Desember
       let newYear = year;
       if (monthSelanjutnya > 11) {
           newYear += 1;
           monthSelanjutnya = 0; // Reset bulan ke Januari
       }
       
       // Membuat objek tanggal baru
       let waktuSelanjutnya = new Date(newYear, monthSelanjutnya, day);
       
       // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
       if (waktuSelanjutnya.getMonth() !== monthSelanjutnya) {
           waktuSelanjutnya = new Date(newYear, monthSelanjutnya + 1, 0); // Set ke hari terakhir bulan sebelumnya
       }
       
       // Format tanggal hasil perhitungan
       let waktuExpiredYear = waktuSelanjutnya.getFullYear();
       let waktuExpiredMonth = String(waktuSelanjutnya.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
       let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(2, '0');
       
       let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;
     
        
        
     
        if(status === "member"){
            if(nama === null || nama ===""||alamat ===null ||
            alamat===""||nomor ===null || nomor ===""|| waktu===null || waktu ==="Pilih"
           ){
                props.ValKirim("kosong");
            }else{
                fetch(url + "/?op=inputMemberBaruGym", {
                    method: "post",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: "rfid=" + rfid+"&nama="+nama+"&alamat="+alamat +"&nomor="+nomor
                    +"&status="+status +"&namaAfiliator="+namaAfiliator +"&nomorHpAfiliator="+
                    nomorHpAfiliator  + "&waktuSaatIni="+formattedDate + "&waktuExpired="+formattedWaktuExpired
                    
                }).then((response)=>response.json())
                .then((json)=>{
                   if(json==="berhasil"){
                    navigate("/gym/attendance");
                  localStorage.removeItem("namaMemberGym");
                   localStorage.removeItem("alamatMemberGym");
                    localStorage.removeItem("nomorMemberGym");
                   localStorage.removeItem("waktuMemberGym");
                  localStorage.removeItem("statusMemberGym");
                  localStorage.removeItem("namaAfiliatorMemberGym");
                    localStorage.removeItem("nomorHpAfiliatorMemberGym");
                    localStorage.removeItem("rfidVisitorGym");
                   }
            
                })
               
            }
        }
        else if((status === "karyawan"||status === "trainer")){
            if(nama === null || nama ===""||alamat ===null ||
            alamat===""||nomor ===null || nomor ===""
           ){
                props.ValKirim("kosong");
            }else{
                fetch(url + "/?op=inputKaryawanAtauTrainerBaruGym", {
                    method: "post",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: "rfid=" + rfid+"&nama="+nama+"&alamat="+alamat +"&nomor="+nomor+"&status="+status + "&waktuSaatIni="+formattedDate
                    
                    
                }).then((response)=>response.json())
                .then((json)=>{
                if(json==="berhasil"){
                   navigate("/gym/attendance");
                   localStorage.removeItem("namaMemberGym");
                   localStorage.removeItem("alamatMemberGym");
                    localStorage.removeItem("nomorMemberGym");
                   localStorage.removeItem("waktuMemberGym");
                  localStorage.removeItem("statusMemberGym");
                  localStorage.removeItem("namaAfiliatorMemberGym");
                    localStorage.removeItem("nomorHpAfiliatorMemberGym");
                    localStorage.removeItem("rfidVisitorGym");
               }
               
                })
            }
        }
        else{
            props.ValKirim("kosong");
        }
 
    }

    // function kirim gym perpanjangan
    const KirimGymPerpanjangan=()=>{
        if(localStorage.getItem("waktuGymPerpanjangan") === null || localStorage.getItem("waktuGymPerpanjangan") ==="Pilih"){
props.ValKirim("kosong")
        }else{
         
            let waktu = localStorage.getItem("waktuGymPerpanjangan");
            let rfid = localStorage.getItem("rfidVisitorGym");
         
           //
          
           let today = new Date();
           let year = today.getFullYear();
           let month = today.getMonth(); // Bulan dimulai dari 0
           let day = today.getDate();
           
           // Format tanggal hari ini
           let formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

      
          let monthSelanjutnya;
          if(waktu === "1 Bulan"){
            monthSelanjutnya = month + 1;
          
          }else if(waktu === "3 Bulan"){
            monthSelanjutnya = month + 3;
          }
          else if(waktu === "6 Bulan"){
            monthSelanjutnya = month + 6;
          }
          else if(waktu === "12 Bulan"){
            monthSelanjutnya = month + 12;
          }
           // Menambahkan satu bulan ke bulan saat ini
       
         
           
           // Penyesuaian tahun jika bulan melampaui Desember
           let newYear = year;
           if (monthSelanjutnya > 11) {
               newYear += 1;
               monthSelanjutnya = 0; // Reset bulan ke Januari
           }
           
           // Membuat objek tanggal baru
           let waktuSelanjutnya = new Date(newYear, monthSelanjutnya, day);
           
           // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
           if (waktuSelanjutnya.getMonth() !== monthSelanjutnya) {
               waktuSelanjutnya = new Date(newYear, monthSelanjutnya + 1, 0); // Set ke hari terakhir bulan sebelumnya
           }
           
           // Format tanggal hasil perhitungan
           let waktuExpiredYear = waktuSelanjutnya.getFullYear();
           let waktuExpiredMonth = String(waktuSelanjutnya.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
           let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(2, '0');
           
           let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;
        
           fetch(url + "/?op=inputGymPerpanjangan", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: "rfid=" + rfid+"&waktuSaatIni="+formattedDate + "&waktuExpired="+formattedWaktuExpired
            
        })
        .then((response)=>response.json())
        .then((json)=>{
            if(json==="berhasil"){
                navigate("/gym/attendance");
               localStorage.removeItem("waktuGymPerpanjangan");
               localStorage.removeItem("rfidVisitorGym");
            }
        })
           
        }
    }

    // function kirim member zumba karyawan atau trainer

    const KirimMemberZumbaBaru=()=>{
        let nama = localStorage.getItem("namaMemberZumba");
        let alamat = localStorage.getItem("alamatMemberZumba");
        let nomor = localStorage.getItem("nomorMemberZumba");
        
        let status = localStorage.getItem("statusMemberZumba");
    
        let rfid = localStorage.getItem("rfidVisitorZumba");
        let today = new Date();
       let year = today.getFullYear();
       let month = today.getMonth(); // Bulan dimulai dari 0
       let day = today.getDate();
       
       // Format tanggal hari ini
       let formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
     
       
        if((status === "karyawan"||status === "trainer")){
            if(nama === null || nama ===""||alamat ===null ||
            alamat===""||nomor ===null || nomor ===""
           ){
                props.ValKirim("kosong");
            }else{
                fetch(url + "/?op=inputKaryawanAtauTrainerBaruZumba", {
                    method: "post",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: "rfid=" + rfid+"&nama="+nama+"&alamat="+alamat +"&nomor="+nomor+"&status="+status + "&waktuSaatIni="+formattedDate
                    
                    
                }).then((response)=>response.json())
                .then((json)=>{
                if(json==="berhasil"){
                   navigate("/zumba/attendance");
                   
        localStorage.removeItem("namaMemberZumba");
        localStorage.removeItem("alamatMemberZumba");
        localStorage.removeItem("nomorMemberZumba");
        localStorage.removeItem("statusMemberZumba");
        localStorage.removeItem("rfidVisitorZumba");
               }
               
                })
            }
        }
        else{
            props.ValKirim("kosong");
        }
 
    }

    //function Klik button
    const Klik=(id)=>{
switch(id){
    case "kirimLogin":
        KirimLogin();
        
        break;
        case "kirimMemberGymBaru":
            KirimMemberGymBaru();
            break;
            case "kirimMemberZumbaBaru":
                KirimMemberZumbaBaru();
                break;
            case "kirimGymPerpanjangan":
                KirimGymPerpanjangan();
                break;
    default:
        break;
}
    }
    return(
        <button onClick={()=>Klik(props.id)} style={{borderRadius:props.borderRadius}} className={style.containerAll}>
{props.nama}
        </button>
    )
}
export default Button;