import React from "react";
import style from "./style.module.css";
import LogoPanah from "../../Photo/panah.png";
import LogoExcel from "../../Photo/logoExcel.png";
import { useState } from "react";
import { useEffect } from "react";
import * as XLSX from 'xlsx';

const OwnerPajak = (props) => {
    const url = props.url;
    const [listData, setListData] = useState([]);
    const [totalPendapatan, setTotalPendapatan] = useState(0);
    const [totalPajak, setTotalPajak] = useState(0);

    useEffect(() => {
        localStorage.setItem("datePajakStart", "");
        localStorage.setItem("datePajakFinish", "");
        AmbilDataIncome();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            AmbilDataIncome();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    function AmbilDataIncome(val) {
        fetch(url + "/?op=getDataIncome", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body:
                "dateStart=" +
                localStorage.getItem("datePajakStart") +
                "&dateFinish=" +
                localStorage.getItem("datePajakFinish"),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json !== null) {
                    setListData(json);
                    let total = 0;

                    total = json.reduce((accumulator, item) => accumulator + parseInt(item.total), 0);
                    setTotalPendapatan(total);
                    setTotalPajak(total * 0.005);
                } else {
                    setListData([]);
                }
            });
    }

    const ChangeDate = (val, id) => {
        switch (id) {
            case "start":
                localStorage.setItem("datePajakStart", val);
                AmbilDataIncome();
                break;
            case "finish":
                localStorage.setItem("datePajakFinish", val);
                AmbilDataIncome();
                break;
            default:
                break;
        }
    };

    const handleExportExcel = () => {
        // Copy listData and append totalPendapatan and totalPajak rows
        const dataToExport = [...listData, {}, { timestamp: '', nama_barang: 'Total Pendapatan', out_barang: '', total: totalPendapatan }, { timestamp: '', nama_barang: 'Total Pajak', out_barang: '', total: totalPajak }];

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "data_pajak.xlsx");
    };

    return (
        <div className={style.containerAll}>
            <div className={style.divAtas}>
                <div className={style.divLogoExcels}>
                <img src={LogoExcel} onClick={handleExportExcel} />
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "start")} />
                <div className={style.divLogoPanah}>
                    <img src={LogoPanah} alt="logo_panah" />
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "finish")} />
            </div>
            <div className={style.divIsi}>
                <div className={style.divData} id="divData">
                    <div className={style.divTable}>
                        <table className={style.table}>
                            <thead>
                                <tr className={style.trSticky}>
                                    <th>Timestamp</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Harga</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.timestamp}</td>
                                        <td>{item.nama_barang}</td>
                                        <td>{item.out_barang}</td>
                                        <td>{item.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={style.divTotalPendapatan}>
                        <div>Total Pendapatan</div>
                        <div>{totalPendapatan}</div>
                    </div>
                    <div className={style.divTotalPajak}>
                        <div>Total Pajak</div>
                        <div>{totalPajak}</div>
                    </div>
                </div>
               
            </div>
        </div>
    );
};

export default OwnerPajak;
