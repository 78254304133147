import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { json } from "react-router-dom";
import style from "./style.module.css";

const OwnerAfiliator =(props)=>{
    const url = props.url;
    const [dataAfiliatorGym,setDataAfiliatorGym]=useState([]);
    const [dataAfiliatorZumba,setDataAfiliatorZumba]=useState([]);
    const [dataDetailAfiliatorZumba,setDataDetailAfiliatorZumba]=useState([]);
    const [dataDetailAfiliatorGym,setDataDetailAfiliatorGym]=useState([]);
    const [divId,setDivId]=useState("afiliatorZumba");
    // fungsi ambil data afiliator gym

        async function AmbilDataAfiliatorGym () {
            let today = new Date();
            let year = today.getFullYear();
            let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
            let day = String(today.getDate()).padStart(2, "0");
          
            let formattedDate = `${year}-${month}-${day}`;
            await fetch(url + "/?op=getDataAfiliatorGym")
            .then((response)=>response.json())
            .then((json)=>{
             if(json!==null){
                let filterExpired = json.filter((item)=>item.expired_member >=formattedDate && item.nama_afiliator!=="null" );

                let filterNamaAfiliator = filterExpired.map((item)=>(
                    {nama_afiliator:item.nama_afiliator,
                    nomor_hp_afiliator:item.nomor_hp_afiliator}));
              
               let dataFilter = filterNamaAfiliator;
               let dataFilterUniqueObj = dataFilter.reduce((acc, item) => {
                const key = `${item.nama_afiliator}-${item.nomor_hp_afiliator}`;
                if (!acc[key]) {
                  acc[key] = item;
                }
                return acc;
              }, {});
              
              // Convert back to an array
              let dataFilterUnique = Object.values(dataFilterUniqueObj);
              
            
             
               const result = dataFilterUnique.map(dataFilterUnique => {
                const count = json.filter(item =>
                  item.nama_afiliator === dataFilterUnique.nama_afiliator &&
                  item.nomor_hp_afiliator === dataFilterUnique.nomor_hp_afiliator&&item.expired_member>=formattedDate
                ).length;
                return { ...dataFilterUnique,jumlah:count };
              });
setDataDetailAfiliatorGym(filterExpired);
             setDataAfiliatorGym(result);
            }
            else{
                setDataDetailAfiliatorGym([]);
             setDataAfiliatorGym([]);
            }
             
            })
    }
    // ambl data afiliator zumba
    const AmbilDataAfiliatorZumba=()=>{
        fetch(url + "/?op=getDataAfiliatorZumba", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: "date=" + localStorage.getItem("dateAfiliatorZumba"),
          })
          .then((response)=>response.json())
          .then((json)=>{
            if(json!==null){
      setDataDetailAfiliatorZumba(json.filter((item)=>item.instruktur !=='-'));
           
let filterNamaTrainer = json.filter((item)=>item.instruktur !=="-").map((item)=>({nama:item.instruktur}))


               let dataFilterUniqueObj = filterNamaTrainer.reduce((acc, item) => {
                const key = `${item.nama}`;
                if (!acc[key]) {
                  acc[key] = item;
                }
                return acc;
              }, {});
              
              // Convert back to an array
              let dataFilterUnique = Object.values(dataFilterUniqueObj);
              const result = dataFilterUnique.map(dataFilterUnique => {
                const count = json.filter(item =>
                  item.instruktur === dataFilterUnique.nama 
        
                ).length;
                return { ...dataFilterUnique,jumlah:count };
              });
          
              setDataAfiliatorZumba(result);
            }else{
                setDataAfiliatorZumba([]);
                setDataDetailAfiliatorZumba([]);
            }
          })
    }
    //
    const ChangeDateAfiliatorZumba=(val)=>{
localStorage.setItem("dateAfiliatorZumba",val);
AmbilDataAfiliatorZumba();
    }
    // klik div id
    const KlikDivId=(val)=>{
        setDivId(val);
    }
    // detail zumba
    const DetailZumba=()=>{
return(
    <div className={style.divDetailZumba}>
<div className={style.judulDetail}>Detail Afiliator Zumba</div>
<div className={style.isiDetail}>
    <table className={style.tableAfiliator}>
        <thead>
            <tr className={style.trSticky}>
                <th>
                   Timestamp 
                </th>
                <th>
                    Nama
                </th>
                <th>
Afiliator
                </th>
            </tr>
        </thead>
        <tbody>
            {dataDetailAfiliatorZumba.map((item,index)=>(
                <tr key={index}>
<td>
    {item.timestamp}
</td>
<td>
    {item.nama}
</td>
<td>
    {item.instruktur}
</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>
    </div>
)
    }
    // detail gym
    const DetailGym=()=>{
        return(
        <div className={style.divDetailZumba}>
        <div className={style.judulDetail}>Detail Afiliator Gym</div>
        <div className={style.isiDetail}>
            <table className={style.tableAfiliator}>
                <thead>
                    <tr className={style.trSticky}>
                        <th>
                           Nama
                        </th>
                        <th>
                            Nomor hp
                        </th>
                        <th>
        Afiliator
                        </th>
                        <th>
        Awal Pendaftaran
                        </th>
                        <th>
        Expired Member
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataDetailAfiliatorGym.map((item,index)=>(
                        <tr key={index}>
        <td>
            {item.nama}
        </td>
        <td>
            {item.nomor_hp}
        </td>
        <td>
            {item.nama_afiliator}
        </td>
        <td>
            {item.awal_pendaftaran}
        </td>
        <td>
            {item.expired_member}
        </td>
        
        
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            </div>)
    }
    // use effect
    useEffect(()=>{
        localStorage.setItem("dateAfiliatorZumba","");
AmbilDataAfiliatorGym();
AmbilDataAfiliatorZumba();
    },[])

    useEffect(()=>{
        // Set an interval to refocus the input field every 100 ms
        const interval = setInterval(() => {
            AmbilDataAfiliatorGym();
            AmbilDataAfiliatorZumba();
        
      }, 5000);
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
    
    return(
        <div className={style.containerAll}>
       <div className={style.containerKiri}>
<div className={style.divAfiliatorGym}>
    <div onClick={() => KlikDivId("afiliatorGym")}
          style={{
            cursor: "pointer",
            color: divId === "afiliatorGym" ? "#2f69fd" : "",
            borderBottom: divId === "afiliatorGym" ? "solid 1px #2f69fd" : "",
          }} className={style.judulAfiliatorGym}>
Afiliator Member Gym
    </div>
    <div className={style.isiAfiliatorGym}>
        <table className={style.tableAfiliator}>
<thead>
    <tr>
        <th>
            Nama
        </th>
        <th>
            Nomor Hp
        </th>
        <th>
            Jumlah Member Aktif
        </th>
    </tr>

</thead>
<tbody>
        {dataAfiliatorGym.map((item,index)=>(
            <tr key={index}>
                <td>{item.nama_afiliator}</td>
                <td>{item.nomor_hp_afiliator}</td>
                <td>{item.jumlah}</td>
            </tr>
        ))}
    </tbody>
        </table>
    </div>
</div>
<div className={style.divAfiliatorGym}>
    <input onChange={(e)=>ChangeDateAfiliatorZumba(e.target.value)} type="date"/>
    <div onClick={() => KlikDivId("afiliatorZumba")}
          style={{
            cursor: "pointer",
            color: divId === "afiliatorZumba" ? "#2f69fd" : "",
            borderBottom: divId === "afiliatorZumba" ? "solid 1px #2f69fd" : "",
          }} className={style.judulAfiliatorGym}>
Kelas Zumba
    </div>
    <div className={style.isiAfiliatorGym}>
        <table className={style.tableAfiliator}>
<thead>
    <tr>
        <th>
            Nama
        </th>
        <th>
            Pengunjung
        </th>
        
    </tr>

</thead>
<tbody>
        {dataAfiliatorZumba.map((item,index)=>(
            <tr key={index}>
                <td>{item.nama}</td>
                <td>{item.jumlah}</td>
                
            </tr>
        ))}
    </tbody>
        </table>
    </div>
</div>
       </div>
       <div className={style.containerKanan}>
        {divId === "afiliatorZumba"?DetailZumba():DetailGym()}
        </div>
        </div>
    )
}
export default OwnerAfiliator;