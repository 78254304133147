import React, { useState } from "react";
import style from "./style.module.css";
import LogoGym from "../../Photo/logoGym.png";
import LogoZumba from "../../Photo/logoZumba.png";
import LogoAttendance from "../../Photo/logoAttendance.png";
import LogoInventory from "../../Photo/logoInventory.png";
import ButtonKotak from "../../Component/ButtonKotak";
import {useNavigate} from 'react-router-dom'; 
const Zumba = () => {
    const navigate=useNavigate();
    const [divGantiAkun, setDivGantiAkun] = useState("notVisibleDivGantiAkun");
    //fucntion klik logo akun
    const KlikLogoAkun=()=>{
        setDivGantiAkun("visibleDivGantiAkun");
    }
    //fucntion klik button ganti akun
    const KlikButton=(val)=>{
        if(val==="ya"){
        navigate("/");
        localStorage.removeItem("loginSebagai");
        }else{
            setDivGantiAkun("notVisibleDivGantiAkun");
        }
    }
    
    return (
        <div className={style.containerAll}>
            <div className={style.divJudul}>
                <div className={style.judul}>
                    Ibu Zumba
                </div>
                <div onClick={()=>KlikLogoAkun()} className={style.akun}>
                    <img src={LogoZumba} alt="Logo Zumba" />
                    <div className={style.divKeteranganAkun}>
                        Akun Zumba
                    </div>
                </div>
                <div className={style[`${divGantiAkun}`]}>
                    <div className={style["judulGantiAkun"]}>
                        Login dengan akun lain
                    </div>
                    <div className={style.pilihanGantiAkun}>
<button onClick={()=>KlikButton("ya")}>Ya</button>
<button onClick={()=>KlikButton("tidak")}>Tidak</button>
                    </div>
                </div>
            </div>
            <div className={style.isi}>
                <div className={style.divButtonKotak}>
                    <ButtonKotak gambar={LogoAttendance} id="attendanceZumba" nama="Attendance" />
                </div>
                <div className={style.divButtonKotak}>
                    <ButtonKotak gambar={LogoInventory} id="inventoryZumba" nama="Inventory" />
                </div>
            </div>
        </div>
    )
}
export default Zumba;



