import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";

const OwnerCashFlow = (props) => {
  const url = props.url;
  const [nilaiTotalSaldo, setNilaiTotalSaldo] = useState("");
  const [dataBarangZumba, setDataBarangZumba] = useState([]);
  const [dataBarang, setDataBarang] = useState([]);
  const [dataFreelance, setDataFreelance] = useState([]);
  const [dataFreelanceZumba, setDataFreelanceZumba] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [totalIncomeInventoryZumba, setTotalIncomeInventoryZumba] = useState(0);
  const [totalIncomeFreelanceZumba, setTotalIncomeFreelanceZumba] = useState(0);
  const [totalOutcomeInventoryZumba, setTotalOutcomeInventoryZumba] =
    useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [jumlahDataFreelanceZumba, setJumlahDataFreelanceZumba] = useState(0);
  const [nilaiDate, setNilaiDate] = useState("");
  const [logoPanah,setLogoPanah]=useState(PanahBawahPolos);
const[divThKeterangan,setDivThKeterangan]=useState("divThKeteranganNone");
const[jumlahDataFreelanceLS,setJumlahDataFreelanceLS]=useState(0);  
//
  const ChangeDatePhp = (val) => {
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateOwnerCashFlow"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang",JSON.stringify(json));
          if(localStorage.getItem("filterStatusDataBarang")==="all"){
          setDataBarang(json);
        }
          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          localStorage.setItem("dataBarang",JSON.stringify([]));
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
    // batas fetch baru
    fetch(url + "/?op=getAmbilDataFreelanceRekapan", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateOwnerCashFlow"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("jumlahDataFreelance",JSON.stringify(json));
          if(localStorage.getItem("filterStatusDataBarang")==="all"){
          setDataFreelance(JSON.parse(localStorage.getItem("jumlahDataFreelance")));
          }
          setJumlahDataFreelance(json.length);
          setTotalIncomeFreelance(json.length * 30000);
        } else {
          localStorage.setItem("jumlahDataFreelance",JSON.stringify([]));
          setDataFreelance([]);
          setJumlahDataFreelance(0);
          setTotalIncomeFreelance(0);
        }
      });
  };
  // ---- sudah Zumba
  const ChangeDatePhpZumba = (val) => {
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateOwnerCashFlow"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataBarangZumba(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventoryZumba(income);
          setTotalOutcomeInventoryZumba(outcome);
        } else {
          setDataBarangZumba([]);
          setTotalIncomeInventoryZumba(0);
          setTotalOutcomeInventoryZumba(0);
        }
      });
    // batas fetch baru
    fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateOwnerCashFlow"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let filterNama = json.map((item) => item.instruktur);
          let harga = {
            zinSusi: 25000,
            zinIcha: 30000,
            aerobic: 15000,
            yoga: 30000,
          };
          let dataFilterUnique = [...new Set(filterNama)];
          const hasil = dataFilterUnique.map((elemen) => {
            const jumlah = json.filter(
              (item) => item.instruktur === elemen
            ).length;
            const total = jumlah * harga[elemen];
            return { nama: elemen, jumlah: jumlah, total: total };
          });
          let hasil2 = json.map((item) => {
            let jumlah2 = harga[item.instruktur];
            return {
              timestamp: item.timestamp,
              nama: item.instruktur,
              jumlah: 1,
              total: jumlah2,
            };
          });
          setDataFreelanceZumba(hasil2);

          setJumlahDataFreelanceZumba(hasil.length);
          let totalIncome = 0;
          for (let i = 0; i < hasil.length; i++) {
            totalIncome = parseInt(totalIncome) + parseInt(hasil[i].total);
          }
          setTotalIncomeFreelanceZumba(totalIncome);
        } else {
          setDataFreelanceZumba([]);
          setJumlahDataFreelanceZumba(0);
          setTotalIncomeFreelanceZumba(0);
        }
      });
  };

  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang",JSON.stringify(json));
          setDataBarang(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
  }

  //
  async function AmbilDataBarangZumba(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataBarangZumba(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventoryZumba(income);
          setTotalOutcomeInventoryZumba(outcome);
        } else {
          setDataBarangZumba([]);
          setTotalIncomeInventoryZumba(0);
          setTotalOutcomeInventoryZumba(0);
        }
      });
  }
  //
  async function AmbilDataFreelance(val) {
    await fetch(url + "/?op=getAmbilDataFreelanceRekapan")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("jumlahDataFreelance",JSON.stringify(json));

          setDataFreelance(JSON.parse(localStorage.getItem("jumlahDataFreelance")));
          setJumlahDataFreelance(json.length);
          setTotalIncomeFreelance(json.length * 30000);
        } else {
          setDataFreelance([]);
          setJumlahDataFreelance(0);
          setTotalIncomeFreelance(0);
        }
      });
  }

  //
  async function AmbilDataFreelanceZumba(val) {
    await fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let filterNama = json.map((item) => item.instruktur);
          let harga = {
            zinSusi: 25000,
            zinIcha: 30000,
            aerobic: 15000,
            yoga: 30000,
          };
          let dataFilterUnique = [...new Set(filterNama)];
          const hasil = dataFilterUnique.map((elemen) => {
            const jumlah = json.filter(
              (item) => item.instruktur === elemen
            ).length;
            const total = jumlah * harga[elemen];
            return { nama: elemen, jumlah: jumlah, total: total };
          });
          let hasil2 = json.map((item) => {
            let jumlah2 = harga[item.instruktur];
            return {
              timestamp: item.timestamp,
              nama: item.instruktur,
              jumlah: 1,
              total: jumlah2,
            };
          });
          setDataFreelanceZumba(hasil2);

          setJumlahDataFreelanceZumba(hasil.length);
          let totalIncome = 0;
          for (let i = 0; i < hasil.length; i++) {
            totalIncome = parseInt(totalIncome) + parseInt(hasil[i].total);
          }
          setTotalIncomeFreelanceZumba(totalIncome);
        } else {
          setDataFreelanceZumba([]);
          setJumlahDataFreelanceZumba(0);
          setTotalIncomeFreelanceZumba(0);
        }
      });
  }
  //
  const KlikTabel = () => {};
  //
  const ChangeDate = (val) => {
    localStorage.setItem("dateOwnerCashFlow",val);
    setNilaiDate(val);
    ChangeDatePhp(val);
    ChangeDatePhpZumba(val);
  };
  //
  useEffect(() => {
    localStorage.setItem("dateOwnerCashFlow","");
    localStorage.setItem("filterStatusDataBarang","all");
    AmbilDataBarang();
    AmbilDataBarangZumba();
    AmbilDataFreelance();
    AmbilDataFreelanceZumba();
  
    
  }, []);
  useEffect(()=>{
      // Set an interval to refocus the input field every 100 ms
      const interval = setInterval(() => {
        ChangeDatePhp();
        ChangeDatePhpZumba();
      
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
}, []);
  
  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div className={style.date}>
          <input
            type="date"
            value={nilaiDate}
            onChange={(e) => ChangeDate(e.target.value)}
          />
        </div>
        <div className={style.tulisanCashFlow}>Total Cash Flow</div>
        <div className={style.tableCashFlow}>
          <table className={style.table}>
            <thead>
              <tr
                onClick={() => {
                  KlikTabel();
                }}
              >
                <th>Item</th>
                <th>Gym</th>
                <th>Zumba</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pemasukan</td>
                <td>{totalIncomeInventory + totalIncomeFreelance}</td>
                <td>{totalIncomeInventoryZumba + totalIncomeFreelanceZumba}</td>
              </tr>
              <tr>
                <td>Pengeluaran</td>
                <td>{totalOutcomeInventory}</td>
                <td>{totalOutcomeInventoryZumba}</td>
              </tr>
              <tr>
                <td>Saldo</td>
                <td>
                  {totalIncomeInventory +
                    totalIncomeFreelance -
                    totalOutcomeInventory}
                </td>
                <td>
                  {" "}
                  {totalIncomeInventoryZumba +
                    totalIncomeFreelanceZumba -
                    totalOutcomeInventoryZumba}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.divTotalSaldo}>
          <div>Total Saldo</div>
          <div>
            {" "}
            {totalIncomeInventory +
              totalIncomeFreelance -
              totalOutcomeInventory +
              totalIncomeInventoryZumba +
              totalIncomeFreelanceZumba -
              totalOutcomeInventoryZumba}
          </div>
        </div>
      </div>
      <div className={style.containerKanan}>
        <div className={style.containerKananPemasukan}>
          <div className={style.judulDetailGym}>Detail Cash Flow Gym</div>
          <div className={style.isiDetailGym}>
            <table className={style.tableGym}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Timestamp</th>
                  <th>nama</th>
                  <th>jumlah</th>
                  <th>Total</th>
                  <th className={style.thKeterangan}>status
                  <img onClick={()=>{
    if(divThKeterangan === "divThKeteranganVisible"){
      setDivThKeterangan("divThKeteranganNone");
    }else{
      setDivThKeterangan("divThKeteranganVisible");
    }
  }} src={logoPanah} />
  <div className={style[`${divThKeterangan}`]}>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")));
      setLogoPanah(PanahBawahPolos);
      setDivThKeterangan("divThKeteranganNone");
      setDataFreelance(JSON.parse(localStorage.getItem("jumlahDataFreelance")));
      localStorage.setItem("filterStatusDataBarang","all");
      }}>all</div>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "outcome"));
      setLogoPanah(PanahBawahIsi);
      setDivThKeterangan("divThKeteranganNone");
      setDataFreelance([]);
      localStorage.setItem("filterStatusDataBarang","outcome");
      }}>outcome</div>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "income"));
      setLogoPanah(PanahBawahIsi);
      setDivThKeterangan("divThKeteranganNone");
      setDataFreelance(JSON.parse(localStorage.getItem("jumlahDataFreelance")));
      localStorage.setItem("filterStatusDataBarang","income");
      }}>income</div>
  </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataBarang.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> {val.nama_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.out_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>{val.keterangan}</td>
                  </tr>
                ))}
                {dataFreelance.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> freelance</td>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ textAlign: "center" }}>30000</td>
                    <td style={{ textAlign: "center" }}>income</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.containerKananPengeluaran}>
          <div className={style.judulDetailZumba}>Detail Cash Flow Zumba</div>
          <div className={style.isiDetailGym}>
            <table className={style.tableGym}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Timestamp</th>
                  <th>nama</th>
                  <th>jumlah</th>
                  <th>Total</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {dataBarangZumba.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> {val.nama_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.out_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>{val.keterangan}</td>
                  </tr>
                ))}
                {dataFreelanceZumba.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> freelance {val.nama}</td>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>income</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
  
};
export default OwnerCashFlow;


